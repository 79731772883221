import { Component } from '@angular/core';

import { CmsModalService } from '../../core/modal.service';
import { CmsNotificationsService } from '../../core/notifications.service';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsTranslateService } from '../../shared/translate.service';
import {CmsAppTemplateSettingsArchiveService} from '../app-template-settings-archive.service'

@Component({
    selector: 'cms-unarchive-template-setting',
    templateUrl: 'unarchive-template-setting.component.html',
    providers: [CmsAppTemplateSettingsArchiveService]
})
export class CmsUnarchiveTemplateSettingComponent {
    data: number;

    constructor(
        private modalService: CmsModalService,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService,
        private appTemplateSettingsArchiveService: CmsAppTemplateSettingsArchiveService
    ) {}

    unarchiveTemplateSetting() {
        this.appTemplateSettingsArchiveService.unarchiveTemplateSetting(this.data).subscribe(
            () => {
                this.notificationsService.show({
                    type: 'action',
                    content: this.translateService.translate('settings_setting_unarchive_success')
                });
                this.closePopup();
                this.notifyService.notify('applicationTemplateSettingUnarchive');
            },
            (err: any) => {
                this.notificationsService.show({
                    type: 'error',
                    content: err.message
                });
            }
        );
    }

    closePopup() {
        this.modalService.close('unarchive-template-setting');
    }
}
